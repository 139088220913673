import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { Field, Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  Form,
  PrimaryButton,
  FieldRadioButton,
  FieldCurrencyInput,
  InfoPopup,
  FieldTextInput,
  FieldSelect,
  NamedLink,
  Button,
  FieldCheckbox,
} from '../../../components';

import css from './ShippingDetailsForm.module.css';
import { composeValidators, numberAtLeast, required } from '../../../util/validators';
import { FREE, OWN, SHIPPING_PREFERENCE, TUTULIST_LOWERCASE, YES } from '../../../util/enums';
import getCountryCodes from '../../../translations/countryCodes';
import config from '../../../config';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import '../../../styles/PhoneNumberInputGlobal.css';
import { SHIPPING } from '../../../util/enums';
import { weightOptions } from '../../../config/custom-config';
import { isArrayLength } from '../../../util/dataExtractors';

const preferenceOptions = [
  { key: TUTULIST_LOWERCASE, label: 'Shipping labels through Tutulist' },
  { key: OWN, label: 'Manage your own shipping' },
];

const shippingOptions = [
  { key: SHIPPING, label: 'Buyer pays shipping' },
  { key: FREE, label: 'Free shipping on all orders' },
];

class ShippingDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneValue: this.props.phoneNumber,
      invalidPhoneNumber: false,
    };
    this.submittedValues = {};
  }

  setPhoneValue(phone) {
    this.setState({ phoneValue: phone });
  }
  setInvalidPhoneValue(bool) {
    this.setState({ invalidPhoneNumber: bool });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
            form,
            hideTitle,
            setCurrentTab = () => {},
            onSaveDefaultToAllListings = () => {},
            TTlWeightWrapper = false,
            ownWeightWrapper = false,
            hasAnyStockGreaterThanOne = false,
            saveWeightInProgress = false,
            isShippingDetailsPage = false,
          } = fieldRenderProps;

          const [showSetDefaultWeight, setShowSetDefaultWeight] = useState(true);
          const [defaultWeightValues, onSetDefaultWeightValues] = useState({
            weight: 0,
            massUnit: 'oz',
          });

          const changePhoneValue = () => {
            if (this.state.phoneValue) {
              form.change('phoneNumber', this.state.phoneValue);
              const formattedNumber = formatPhoneNumberIntl(this.state.phoneValue);
              if (isValidPhoneNumber(formattedNumber)) {
                this.setInvalidPhoneValue(false);
              } else {
                this.setInvalidPhoneValue(true);
              }
            } else {
              form.change('phoneNumber', null);
            }
          };

          const isTutulist = values?.shippingPreference === TUTULIST_LOWERCASE;
          const shouldShowThreshold = values?.shippingType === SHIPPING;

          const classes = classNames(rootClassName || css.root, className);
          const shippingClasses = classNames(css.deliveryOption);
          const submitDisabled = invalid || inProgress || (isTutulist && !values?.phoneNumber);

          const streetAddressLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.streetAddress',
          });
          const streetAddressPlaceholderMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.streetAddressPlaceholder',
          });
          const streetAddressRequiredMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.streetAddressRequired',
          });

          const buildingLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.building',
          });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.buildingPlaceholder',
          });

          const postalLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.postal',
          });
          const postalPlaceholderMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.postalPlaceholder',
          });
          const postalRequiredMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.postalRequired',
          });

          const defaultWeightLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.defaultWeight',
          });
          const defaultWeightRequiredMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.defaultWeightRequired',
          });

          const cityLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.city',
          });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.cityPlaceholder',
          });
          const cityRequiredMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.cityRequired',
          });

          const stateLabelMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.state',
          });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.statePlaceholder',
          });
          const stateRequiredMessage = intl.formatMessage({
            id: 'ShippingDetailsForm.stateRequired',
          });
          const phoneNumberLabel = intl.formatMessage({
            id: 'ShippingDetailsForm.phoneNumberLabel',
          });
          const phoneNumberError = intl.formatMessage({
            id: 'ShippingDetailsForm.phoneNumberError',
          });
          const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
          const countryPlaceholder = intl.formatMessage({
            id: 'StripePaymentAddress.countryPlaceholder',
          });
          const countryRequired = required(
            intl.formatMessage({
              id: 'StripePaymentAddress.countryRequired',
            })
          );

          const lengthLabel = intl.formatMessage({ id: 'EditListingShippingForm.lengthLabel' });
          const lengthPlaceholderMessage = intl.formatMessage({
            id: 'EditListingShippingForm.lengthPlaceholderMessage',
          });
          const lengthRequiredMessage = intl.formatMessage({
            id: 'EditListingShippingForm.lengthRequiredMessage',
          });

          const widthLabel = intl.formatMessage({ id: 'EditListingShippingForm.widthLabel' });
          const widthPlaceholderMessage = intl.formatMessage({
            id: 'EditListingShippingForm.widthPlaceholderMessage',
          });
          const widthRequiredMessage = intl.formatMessage({
            id: 'EditListingShippingForm.widthRequiredMessage',
          });

          const heightLabel = intl.formatMessage({ id: 'EditListingShippingForm.heightLabel' });
          const heightPlaceholderMessage = intl.formatMessage({
            id: 'EditListingShippingForm.heightPlaceholderMessage',
          });
          const heightRequiredMessage = intl.formatMessage({
            id: 'EditListingShippingForm.heightRequiredMessage',
          });
          const phonePlaceholder = intl.formatMessage({
            id: 'EditListingShippingForm.phonePlaceholder',
          });

          const shippingLabel = intl.formatMessage({ id: 'EditListingDeliveryForm.shippingLabel' });
          const noShippingLabel = intl.formatMessage({
            id: 'EditListingDeliveryForm.noShippingLabel',
          });

          const isOwnShipping =
            isArrayLength(values?.deliveryOptions) && values?.deliveryOptions?.includes('shipping');
          const isOwnFreeShipping =
            isArrayLength(values?.deliveryOptions) &&
            values?.deliveryOptions?.includes('freeShipping');

          const onShippingChange = e => {
            const id = e.target.id;
            if (id === 'tutulist') {
              form.change('deliveryOptions', ['tutulist']);
            } else if (id == 'shipping') {
              form.change('deliveryOptions', ['shipping']);
            } else if (id == 'freeShipping') {
              form.change('deliveryOptions', ['freeShipping']);
              form.change('shippingPriceInSubunitsOneItem', null);
            }
          };

          const renderDimensions = (
            <div className={css.defaultPackage}>
              <p className={css.packageSize}>
                {intl.formatMessage({
                  id: 'ShippingDetailsForm.packageSize',
                })}
                <InfoPopup info="Please note that changes to this will impact rates" />
              </p>
              <div className={css.formRow}>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.input}
                    type="number"
                    id="length"
                    name="length"
                    label={lengthLabel}
                    placeholder={lengthPlaceholderMessage}
                    validate={composeValidators(
                      required(lengthRequiredMessage),
                      numberAtLeast(
                        intl.formatMessage({ id: 'ShippingDetailsForm.minimumError' }),
                        1
                      )
                    )}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.input}
                    type="number"
                    id="width"
                    name="width"
                    label={widthLabel}
                    placeholder={widthPlaceholderMessage}
                    validate={composeValidators(
                      required(widthRequiredMessage),
                      numberAtLeast(
                        intl.formatMessage({ id: 'ShippingDetailsForm.minimumError' }),
                        1
                      )
                    )}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.input}
                    type="number"
                    id="height"
                    name="height"
                    label={heightLabel}
                    placeholder={heightPlaceholderMessage}
                    validate={composeValidators(
                      required(heightRequiredMessage),
                      numberAtLeast(
                        intl.formatMessage({ id: 'ShippingDetailsForm.minimumError' }),
                        1
                      )
                    )}
                  />
                </div>
              </div>
            </div>
          );

          const renderTutulistOptions = (
            <>
              <div className={css.shippingOptions}>
                {shippingOptions.map(item => (
                  <Field name="shippingType" key={item.key} type="radio" value={item.key}>
                    {({ input }) => {
                      return (
                        <>
                          <input
                            {...input}
                            id={item.key}
                            type="radio"
                            checked={values?.shippingType === item.key}
                            onClick={e => {
                              input.onChange(e.target.value);
                            }}
                          />
                          <label
                            htmlFor={item.key}
                            role="button"
                            onClick={() => form.change('shippingType', item.key)}
                          >
                            {item.key === FREE ? (
                              <>
                                Free shipping on all orders{' '}
                                <InfoPopup info="The cost of shipping will be deducted from your sales proceeds." />
                              </>
                            ) : (
                              item.label
                            )}
                          </label>
                        </>
                      );
                    }}
                  </Field>
                ))}
              </div>
              {shouldShowThreshold ? (
                <div className={css.shippingPayment}>
                  <label htmlFor="threshold">
                    {intl.formatMessage({
                      id: 'ShippingDetailsForm.doYouWantToAddFreeShippingThreshold',
                    })}
                    <InfoPopup info="E.g. offer free shipping on orders of $100 or more." />
                  </label>
                  <div className={css.actions}>
                    {[
                      { key: 'no', label: 'No' },
                      { key: 'yes', label: 'Yes' },
                    ].map(item => (
                      <FieldRadioButton
                        key={item.key}
                        id={item.key}
                        name="threshold"
                        label={item.label}
                        value={item.key}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
              {values?.threshold === YES && shouldShowThreshold ? (
                <div className={classNames(css.formRow, css.thresholdAmount)}>
                  <div className={css.formFld}>
                    <div className={shippingClasses}>
                      <FieldCurrencyInput
                        id="thresholdAmount"
                        key="thresholdAmount"
                        name="thresholdAmount"
                        className={classNames(css.input)}
                        label={intl.formatMessage({
                          id: 'EditListingShippingForm.thresholdAmountLabel',
                        })}
                        currencyConfig={config.currencyConfig}
                        validate={required(
                          intl.formatMessage({
                            id: 'EditListingShippingForm.thresholdAmountRequired',
                          })
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {renderDimensions}
              <h3 className={css.sectionTitle}>
                <FormattedMessage id="ShippingDetailsForm.shippingAddressTitle" />
              </h3>
              <div className={css.formRow}>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.address` : 'address'}
                    type="text"
                    name="line1"
                    label={streetAddressLabelMessage}
                    placeholder={streetAddressPlaceholderMessage}
                    validate={required(streetAddressRequiredMessage)}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.secondInput}
                    id={formId ? `${formId}.apt` : 'apt'}
                    type="text"
                    name="line2"
                    label={buildingLabelMessage}
                    placeholder={buildingPlaceholderMessage}
                  />
                </div>
              </div>
              <div className={css.formRow}>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.city` : 'city'}
                    type="text"
                    name="city"
                    label={cityLabelMessage}
                    placeholder={cityPlaceholderMessage}
                    validate={required(cityRequiredMessage)}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.secondInput}
                    id={formId ? `${formId}.zip` : 'zip'}
                    type="text"
                    name="postalCode"
                    label={postalLabelMessage}
                    placeholder={postalPlaceholderMessage}
                    validate={required(postalRequiredMessage)}
                  />
                </div>
              </div>
              <div className={css.formRow}>
                <div className={css.formFld}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.state` : 'state'}
                    type="text"
                    name="state"
                    label={stateLabelMessage}
                    placeholder={statePlaceholderMessage}
                    validate={required(stateRequiredMessage)}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldSelect
                    id={formId ? `${formId}.country` : 'country'}
                    name="country"
                    className={css.field}
                    label={countryLabel}
                    initialValue={values?.country || 'US'}
                    validate={countryRequired}
                  >
                    <option disabled value="">
                      {countryPlaceholder}
                    </option>
                    {getCountryCodes(config.locale).map(country => {
                      return (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
              </div>
              {/* <div className={classNames(css.formRow, css.phoneNumber)}>
                <div className={css.formFld}>
                  <FieldCheckbox
                    id="disableNonUSShipping"
                    className={css.deliveryCheckbox}
                    name="disableNonUSShipping"
                    label="Disable non-US orders"
                    value="yes"
                  />
                </div>
              </div> */}
              <div className={classNames(css.formRow, css.phoneNumber)}>
                <div className={css.formFld}>
                  <label htmlFor="phoneNumber">{phoneNumberLabel}</label>
                  <PhoneInput
                    defaultCountry="US"
                    placeholder={phonePlaceholder}
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    value={values?.phoneNumber || this.state.phoneValue}
                    onChange={phone => this.setPhoneValue(phone)}
                    onMouseOut={changePhoneValue}
                    className={css.phoneNumber}
                  />
                </div>
              </div>
              {this.state.invalidPhoneNumber && (
                <span className={css.error}>{phoneNumberError}</span>
              )}
            </>
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <div className={classNames(css.sectionContainer, css.lastSection)}>
                  {hideTitle ? null : (
                    <h2 className={css.shippingPreference}>
                      {intl.formatMessage({
                        id: 'ShippingDetailsForm.shippingPreference',
                      })}
                    </h2>
                  )}
                  <div className={css.shippingOptions}>
                    {preferenceOptions.map(item => (
                      <FieldRadioButton
                        key={item.key}
                        id={item.key}
                        name={SHIPPING_PREFERENCE}
                        label={item.label}
                        value={item.key}
                        onChange={e => {
                          form.change(SHIPPING_PREFERENCE, e.target.value);
                          form.change('shippingType', SHIPPING);
                          form.change('threshold', 'no');
                          setCurrentTab(e.target.value);
                        }}
                        validate={required(
                          intl.formatMessage({
                            id: 'ShippingDetailsForm.shippingPreferenceRequired',
                          })
                        )}
                      />
                    ))}
                  </div>

                  {/* {!isTutulist ? (
                    <div className={css.nonUSOptions}>
                      <span className={css.nonUSOptionsHeading}>
                        Do you want to allow non-US orders?
                      </span>
                      <FieldRadioButton
                        id="enableUSShipping"
                        name="enableUSOrders"
                        label="Yes"
                        value="yes"
                      />
                      <FieldRadioButton
                        id="disableUSShipping"
                        name="enableUSOrders"
                        label="No"
                        value="no"
                      />
                    </div>
                  ) : null} */}

                  {isTutulist && !TTlWeightWrapper ? (
                    <div className={css.tutulistOptionBlock}>{renderTutulistOptions}</div>
                  ) : null}
                  {TTlWeightWrapper && isShippingDetailsPage ? (
                    <div className={css.tutulistOptionBlock}>
                      <p>
                        Some of your products are missing the weight which is required to ship
                        through Tutulist.{' '}
                      </p>
                      <p>Please set a default weight for all of your products:</p>
                      {showSetDefaultWeight ? (
                        <>
                          <label for="defaultWeight">{defaultWeightLabelMessage}</label>
                          <div className={css.showSetDefaultWeight}>
                            <input
                              className={css.secondInput}
                              id="defaultWeight"
                              type="number"
                              name="defaultWeight"
                              value={defaultWeightValues?.weight}
                              onChange={e =>
                                onSetDefaultWeightValues({
                                  ...defaultWeightValues,
                                  weight: e.target.value,
                                })
                              }
                              validate={required(defaultWeightRequiredMessage)}
                            />
                            {/* <label for="massUnit">{massUnitLabelMessage}</label> */}
                            <select
                              id="massUnit"
                              name="massUnit"
                              defaultValue="oz"
                              onChange={e =>
                                onSetDefaultWeightValues({
                                  ...defaultWeightValues,
                                  massUnit: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled></option>
                              {weightOptions.map(item => (
                                <option key={item.key} value={item.key}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className={css.saveButton}>
                            <Button
                              inProgress={saveWeightInProgress}
                              type="button"
                              onClick={() => onSaveDefaultToAllListings(defaultWeightValues)}
                              disabled={defaultWeightValues?.weight <= 0}
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      ) : null}
                      or
                      <p>
                        Update them manually by editing your listings{' '}
                        <NamedLink className={css.hereText} name="ManageListingsPage">
                          here.
                        </NamedLink>
                      </p>
                    </div>
                  ) : null}
                  {ownWeightWrapper && isShippingDetailsPage ? (
                    <div className={css.tutulistOptionBlock}>
                      <p>
                        Some of your products are missing the shipping price which is required to
                        ship when choosing own shipping.{' '}
                      </p>{' '}
                      {showSetDefaultWeight ? (
                        <>
                          <FieldCheckbox
                            id="shipping"
                            className={css.deliveryCheckbox}
                            name="deliveryOptions"
                            label={shippingLabel}
                            value="shipping"
                            onChange={onShippingChange}
                          />
                          <FieldCheckbox
                            id="freeShipping"
                            className={classNames(css.deliveryCheckbox, css.noShipping)}
                            name="deliveryOptions"
                            label={noShippingLabel}
                            value="freeShipping"
                            onChange={onShippingChange}
                          />
                          {isOwnShipping ? (
                            <div className={css.showSetDefaultWeight}>
                              <FieldCurrencyInput
                                key="oneItemValidation"
                                id="shippingPriceInSubunitsOneItem"
                                name="shippingPriceInSubunitsOneItem"
                                className={css.input}
                                label={intl.formatMessage({
                                  id: 'EditListingDeliveryForm.shippingOneItemLabel',
                                })}
                                placeholder={intl.formatMessage({
                                  id: 'EditListingDeliveryForm.shippingOneItemPlaceholder',
                                })}
                                currencyConfig={config.currencyConfig}
                                validate={required(
                                  intl.formatMessage({
                                    id: 'EditListingDeliveryForm.shippingOneItemRequired',
                                  })
                                )}
                              />
                            </div>
                          ) : null}
                          <div className={css.saveButton}>
                            <Button
                              inProgress={saveWeightInProgress}
                              type="button"
                              onClick={() =>
                                onSaveDefaultToAllListings({
                                  shippingPriceInSubunitsOneItem:
                                    values?.shippingPriceInSubunitsOneItem?.amount,
                                  deliveryOptions: values?.deliveryOptions,
                                  isShippingParams: true,
                                })
                              }
                              disabled={
                                !isOwnFreeShipping && !values?.shippingPriceInSubunitsOneItem
                              }
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      ) : null}
                      or
                      <p>
                        Update them manually by editing your listings
                        <NamedLink className={css.hereText} name="ManageListingsPage">
                          here.
                        </NamedLink>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              {TTlWeightWrapper || ownWeightWrapper ? null : (
                <div className={css.bottomWrapper}>
                  <PrimaryButton type="submit" inProgress={inProgress} disabled={submitDisabled}>
                    <FormattedMessage id="ShippingDetailsForm.saveChanges" />
                  </PrimaryButton>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

ShippingDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
};

const { bool, func, string } = PropTypes;

ShippingDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const ShippingDetailsForm = compose(injectIntl)(ShippingDetailsFormComponent);

ShippingDetailsForm.displayName = 'ShippingDetailsForm';

export default ShippingDetailsForm;
