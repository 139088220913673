export const weightOptions = [
  {
    key: 'g',
    label: 'g',
  },
  {
    key: 'kg',
    label: 'kg',
  },
  {
    key: 'oz',
    label: 'oz',
  },
  {
    key: 'lb',
    label: 'lb',
  },
];
